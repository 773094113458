<template>
  <div class="foods">
    <NavbarItem />

    <!-- Container -->
    <div class="container">
      <!-- Body -->
      <div class="row mt-4">
        <div class="col">
          <h2>Daftar <strong>Makanan</strong></h2>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <div class="input-group mt-3">
            <input
              v-model="searchParam"
              type="text"
              class="form-control"
              placeholder="Ketik makanan yang ingin Anda cari ... "
              aria-label="Search"
              aria-describedby="basic-addon1"
              @keyup="searchFood"
            />
            <span class="input-group-text" id="basic-addon1"
              ><b-icon-search></b-icon-search
            ></span>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div
          class="col-md-4 mt-4"
          v-for="product in products"
          :key="product.id"
        >
          <CardProductItem :product="product" />
        </div>
      </div>
      <!-- End Body -->
    </div>
    <!-- End Container -->
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarItem from "@/components/Navbar.vue";
import CardProductItem from "@/components/CardProduct.vue";
import axios from "axios";

export default {
  // Component View
  name: "FoodsView",
  components: {
    NavbarItem,
    CardProductItem,
  },
  // Api Componen
  // Set Data -> Inisialisasi product (Kosong)
  data() {
    return {
      api: process.env.VUE_APP_GLOBAL_API,
      products: [],
      searchParam: "",
    };
  },
  // Method Api
  methods: {
    setAllProduct(data) {
      this.products = data;
    },
    searchFood() {
      // Cek Panjang Parameter
      if (this.searchParam.length >= 3) {
        axios
          .get(this.api + "/products?search=" + this.searchParam) //Url Paramater + Param request
          .then((response) => this.setAllProduct(response.data.data)) //Jika Berhasil, jalankan fungsi setallproduct, isi data dengan response array data
          .catch((error) => console.log("Gagal : ", error)); //Jika Gagal feedback
      } else if (this.searchParam.length == 0) {
        axios
          .get(this.api + '/products') //Url Paramater
          .then((response) => this.setAllProduct(response.data.data)) //Jika Berhasil, jalankan fungsi setallproduct, isi data dengan response array data
          .catch((error) => console.log("Gagal : ", error)); //Jika Gagal feedback
      }
    },
  },
  // Request Axios
  mounted() {
    axios
      .get(this.api + '/products') //Url Paramater
      .then((response) => this.setAllProduct(response.data.data)) //Jika Berhasil, jalankan fungsi setallproduct, isi data dengan response array data
      .catch((error) => console.log("Gagal : ", error)); //Jika Gagal feedback
  },
};
</script>

<style>
</style>