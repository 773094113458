<template>
  <div class="food-deail">
    <NavbarItem />
    <div class="container">
      <!-- BreadCumb -->
      <div class="row mt-4">
        <div class="col">
          <nav aria-label="breadcrumb mt-4">
            <ol class="breadcrumb">
              <router-link class="breadcrumb-item" to="/">Home</router-link>
              <router-link class="breadcrumb-item" to="/foods"
                >Foods</router-link
              >
              <router-link
                class="breadcrumb-item active"
                :to="'/food/' + this.$route.params.id"
                >Data Product</router-link
              >
            </ol>
          </nav>
        </div>
      </div>
      <!-- End BreadCumb -->

      <!-- Detail Product -->
      <div class="row mt-3">
        <!-- Gambar Makanan -->
        <div class="col-md-6">
          <img
            :src="'../assets/images/makanan/' + product.gambar"
            :alt="this.product.nama"
            class="img-fluid shadow"
          />
        </div>

        <!-- Detail -->
        <div class="col-md-6">
          <h2>
            <strong> {{ product.nama }} </strong>
          </h2>
          <hr />
          <h4>
            Harga :
            <strong>Rp. {{ product.harga }} </strong>
          </h4>
          <form class="mt-4" v-on:submit.prevent>
            <div class="form-group">
              <label for="jumlah_pemesanan">Jumlah Pesan</label>
              <input
                v-model="pesanan.jumlah"
                type="number"
                class="form-control"
                value="1"
                min="1"
              />
            </div>
            <div class="form-group">
              <label for="keterangan">Keterangan</label>
              <textarea
                v-model="pesanan.keterangan"
                class="form-control"
                placeholder="Keterangan spt : Pedes, Nasi Setengah .."
              ></textarea>
            </div>
            <button type="submit" class="btn btn-success" @click="setPesanan">
              <b-icon-cart></b-icon-cart> Pesan
            </button>
          </form>
        </div>
        <!-- End Detail -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarItem from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    NavbarItem,
  },
  data() {
    return {
      api: process.env.VUE_APP_GLOBAL_API,
      product: {},
      pesanan: {},
    };
  },
  methods: {
    // Data Produk Detail
    setProductDetail(data) {
      this.product = data;
    },
    // Masukan Ke Keranjang
    setPesanan() {
      if (this.pesanan.jumlah > 0) {
        this.pesanan.product_id = this.product.id;
        this.pesanan.keterangan = (this.pesanan.keterangan) ? this.pesanan.keterangan : 'None';
        axios
          .post(this.api + '/carts', this.pesanan)
          .then(() => {
            this.$router.push({ path: "/keranjang" })
            this.$toast.success("Kerangjang Berhasil di Update!", {
              type: "success",
              position: "top-right",
              duration: 3000,
              dismissable: true,
            });
          })
          .catch((error) => console.log("Gagal!", error));

      } else {
        this.$toast.error("Jumlah pesanan harus di isi!", {
          type: "error",
          position: "top-right",
          duration: 3000,
          dismissable: true,
        });
      }
    },
  },
  mounted() {
    axios
      .get(this.api + '/products/' + this.$route.params.id)
      .then((response) => this.setProductDetail(response.data.data))
      .catch((error) => console.log("Gagal : ", error));
  },
};
</script>