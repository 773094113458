<template>
  <div class="keranjang-view">
    <NavbarItem :updateKeranjang="keranjangs" />
    <div class="container">
      <!-- BreadCumb -->
      <div class="row mt-4">
        <div class="col">
          <nav aria-label="breadcrumb mt-4">
            <ol class="breadcrumb">
              <router-link class="breadcrumb-item" to="/">Home</router-link>
              <router-link class="breadcrumb-item" to="/foods"
                >Foods</router-link
              >
              <router-link class="breadcrumb-item active" to="/keranjang"
                >Keranjang</router-link
              >
            </ol>
          </nav>
        </div>
      </div>
      <!-- End BreadCumb -->

      <!-- Jika Keranjang Ada -->
      <div v-if="keranjangs.length">
        <!-- Row -->
        <div class="row">
          <div class="col">Keranjang <strong>Saya </strong></div>
          <div class="table-responsive mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Foto</th>
                  <th scope="col">Makanan</th>
                  <th scope="col">Keterangan</th>
                  <th scope="col">Jumlah</th>
                  <th scope="col">Harga</th>
                  <th scope="col">Total Harga</th>
                  <th scope="col">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(keranjang, index) in keranjangs"
                  :key="keranjang.id"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      :src="
                        '../assets/images/makanan/' + keranjang.products.gambar
                      "
                      class="img-fluid shadow"
                      width="250"
                    />
                  </td>
                  <td>{{ keranjang.products.nama }}</td>
                  <td>
                    {{ keranjang.keterangan ? keranjang.keterangan : " - " }}
                  </td>
                  <td>{{ keranjang.jumlah }}</td>
                  <td align="right">Rp. {{ keranjang.products.harga }}</td>
                  <td align="right">
                    <strong
                      >Rp. {{ keranjang.jumlah * keranjang.products.harga }}
                    </strong>
                  </td>
                  <td align="center" class="text-danger">
                    <b-icon-trash
                      @click="hapusKeranjang(keranjang.id)"
                    ></b-icon-trash>
                  </td>
                </tr>
                <tr>
                  <td colspan="6" align="right">
                    <strong>Total Harga : </strong>
                  </td>
                  <td colspan="2" alig="left">
                    <strong>Rp. {{ totalHarga }} </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- End Row -->

        <!-- Form Checkout -->
        <div class="row justify-content-end">
          <div class="col-md-4">
            <form class="mt-4" v-on:submit.prevent>
              <div class="form-group">
                <label for="nama">Nama :</label>
                <input type="text" class="form-control" v-model="pesan.nama" />
              </div>
              <div class="form-group">
                <label for="noMeja">Nomor Meja :</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="pesan.nomor_meja"
                />
              </div>

              <button
                type="submit"
                class="btn btn-success float-right"
                @click="checkout"
              >
                <b-icon-cart></b-icon-cart>Pesan
              </button>
            </form>
          </div>
        </div>
        <!-- End Form Checkout -->
      </div>
      <!-- Jika Keranjang Kosong -->
      <div v-else>
          <div class="row mt-5">
              <div class="col">
                  <strong>Keranjang Anda masih Kosong! silahkan pesan sesuatu.</strong>
              </div>
          </div>
      </div>


    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarItem from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "KeranjangView",
  components: {
    NavbarItem,
  },
  data() {
    return {
      api: process.env.VUE_APP_GLOBAL_API,
      keranjangs: [],
      pesan: {},
    };
  },
  methods: {
    setKeranjangs(data) {
      this.keranjangs = data;
    },
    hapusKeranjang(id) {
      axios
        .delete(this.api + '/carts/' + id)
        .then(() => {
          // Toast Sukses
          this.$toast.warning("Pesanan telah di hapus!", {
            type: "warning",
            position: "top-right",
            duration: 3000,
            dismissable: true,
          });

          //   Update / Reload keranjang
          axios
            .get(this.api + '/carts')
            .then((response) => this.setKeranjangs(response.data.data))
            .catch((error) => console.log("Gagal : ", error));
        })
        .catch((error) => console.log("Gagal : ", error));
    },
    // CheckOut
    checkout() {
      if (this.pesan.nama && this.pesan.nomor_meja) {
        this.pesan.carts = this.keranjangs;
        axios
          .post(this.api + '/orders', this.pesan)
          .then(() => {

            // Hapus Semua Keranjang
            this.keranjangs.map(function (item) {
              return axios
                .delete(process.env.VUE_APP_GLOBAL_API + '/carts/' + item.id)
                .catch((error) => console.log(error));
            });

            this.$router.push({ path: "/pesanan-sukses" });
            this.$toast.success("Sukses Dipesan", {
              type: "success",
              position: "top-right",
              duration: 3000,
              dismissible: true,
            });
          })
          .catch((err) => console.log(err));
      } else {
        this.$toast.error("Nama dan Nomor Meja Harus diisi", {
          type: "error",
          position: "top-right",
          duration: 3000,
          dismissible: true,
        });
      }
    },
    // End CheckOut
  },
  mounted() {
    axios
      .get(this.api + '/carts')
      .then((response) => this.setKeranjangs(response.data.data))
      .catch((error) => console.log("Gagal : ", error));
  },
  computed: {
    totalHarga() {
      return this.keranjangs.reduce(function (items, data) {
        return items + data.products.harga * data.jumlah;
      }, 0);
    },
  },
};
</script>

<style>
</style>