<template>
  <div id="app">
    <router-view/>
    <FooterItem />
  </div>
</template>

<script>
// Import File
import FooterItem from "@/components/Footer.vue"

export default {
  components: {
    FooterItem
  }
}

</script>
