<template>
  <div class="cardProduk">
    <!-- Card Body -->
    <div class="card shadow card-product">
      <img
        :src="'assets/images/makanan/' + product.gambar"
        class="card-img-top"
        :alt="product.nama"
      />
      <div class="card-body">
        <h5 class="card-title"> {{ product.nama }} </h5>
        <p class="card-text">Harga : Rp. {{ product.harga }} </p>
        <router-link class="btn btn-success float-right" :to="'/food/' + product.id" ><b-icon-cart></b-icon-cart> Pesan</router-link>
      </div>
    </div>
    <!-- End Card -->
  </div>
</template>

<script>
export default {
  name: "CardProductItem",
  props: ["product"],
};
</script>

<style>
</style>