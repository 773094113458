<template>
  <div class="home">
    <NavbarItem />
    <div class="container">
      <HeroItem />

      <div class="row mt-4">
        <div class="col">
          <h2>
            Best
            <strong>Foods</strong>
          </h2>
        </div>
        <div class="col">
          <router-link to="/foods" class="btn btn-success float-right">
            <b-icon-eye></b-icon-eye>Lihat Semua
          </router-link>
        </div>
      </div>

      <div class="row mb-4">
        <div
          class="col-md-4 mt-4"
          v-for="product in products"
          :key="product.id"
        >
          <CardProductItem :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarItem from "@/components/Navbar.vue";
import HeroItem from "@/components/Hero.vue";
import CardProductItem from "@/components/CardProduct.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    NavbarItem,
    HeroItem,
    CardProductItem,
  },
  data() {
    return {
      api: process.env.VUE_APP_GLOBAL_API,
      products: [],
    };
  },
  methods: {
    setProduct(data) {
      this.products = data;
    },
  },
  mounted() {
    axios
      .get(this.api + '/best-products')
      .then((response) => this.setProduct(response.data.data))
      .catch((error) => console.log("Error : ", error));
  },
};
</script>
